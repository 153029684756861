import React from "react";
import { Box } from "@chakra-ui/react";

export const MapComponent = () => {
  return (
    <Box
      width={{ base: "90vw", sm: "80vw", md: "65vw" }}
      height={{ base: "300px", sm: "350px", md: "400px" }}
      border="1px solid #ccc"
      borderRadius="8px"
      overflow="hidden"
    >
      <iframe
        title="MKK"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.0731045898615!2d77.26273871121273!3d28.657529675549462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfde7be3acefd%3A0xa7f8f4e758b08bca!2sMAA%20KI%20KRIPA%20SALES%20LLP!5e0!3m2!1sen!2sin!4v1732795893856!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};
