import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useCalculatedBreakpoints } from '../hooks/useCalculateedBreakouts';

export const CopyRights = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  return (
    <Box p="10px" bg="red.500" w="full">
      <Text align="start" color="red.500" fontSize={isDesktop ? "2xl" : "small"}>Maa Ki Kripa Sales LLP © 2024</Text>
    </Box>
  );
}