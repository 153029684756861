import React from "react";
import { VStack, Stack, Text, HStack, Img, Box } from "@chakra-ui/react";
import delhi from "../assets/delhi.png";
import kanpur from "../assets/kanpur.png";
import kolkata from "../assets/kolkata.png";
import { MdLocationOn, MdPhoneIphone } from "react-icons/md";

export const Location = (props) => {
  const { location } = props;
  return (
    <VStack>
      <HStack w="full" justify="space-between">
        <Img
          src={location.id === 2 ? delhi : location.id === 3 ? kanpur : kolkata}
          height="150px"
          width="150px"
        ></Img>
        <Stack
          align="flex-start"
          spacing="12px"
          width="100%"
        >
          <Text color="red.500" fontSize="x-large" fontWeight="bold">
            {location.title}
          </Text>
          <VStack align="flex-start">
            {/* Address */}
            <HStack alignItems="center">
              <Box>
                <MdLocationOn size="21px" />
              </Box>
              <Text fontSize="large" textAlign="left" lineHeight="1.4">
                {location.address} <br />
                {location.city}
              </Text>
            </HStack>

            {/* Phone */}
            <HStack alignItems="center">
              <Box>
                <MdPhoneIphone size="20px" />
              </Box>
              <Text fontSize="large" textAlign="left" lineHeight="1.4">
                {location.phoneNum}
              </Text>
            </HStack>
          </VStack>
        </Stack>
      </HStack>
    </VStack>
  );
};
