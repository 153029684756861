import "./App.css";
import { About } from "./components/About";
import { OurBranches } from "./components/OurBranches";
import { ContactUs } from "./components/ContactUs"
import { Home } from "./components/Home.jsx";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Products } from "./components/Products";
import { Footer } from "./components/Footer";
import { Box, Flex } from "@chakra-ui/react";

function App() {
  return (
    <Flex direction="column" minHeight="100vh" bg="white">
      <Box flex="1">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/products" element={<Products />} />
            {/* <Route path="/offers" element={<Offers />} /> */}
            <Route path="/our-branches" element={<OurBranches />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </Box>
      <Footer />
    </Flex>
  );
}

export default App;
