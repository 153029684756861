import React from "react";
import { HStack, VStack, Stack, Text, Box } from "@chakra-ui/react";
import { MdLocationOn, MdPhoneIphone } from "react-icons/md";

export const AddressCard = () => {
  return (
    <Box
      bg="red.50"
      borderRadius="16px"
      p={{ base: "16px", sm: "24px" }}
      boxShadow="md"
      display="flex"
      flexDirection="column"
      width={{ base: "90vw", sm: "80vw", md: "30vw" }}
      justifyContent="center"
      alignItems="center"
      height="auto"
      minHeight="400px"
    >
      <Stack
        spacing="12px"
        width="80%"
        justifyContent="center"
        alignItems="center"
      >
        {/* Title */}
        <Text color="red.500" fontSize="x-large" fontWeight="bold">
          Contact Us
        </Text>

        <VStack align="flex-start" spacing="16px">
          {/* Address */}
          <HStack alignItems="center" spacing="8px">
            <Box>
              <MdLocationOn size="21px" />
            </Box>
            <Text fontSize="large" textAlign="left" lineHeight="1.4">
              9/3, Ground floor, Geeta Colony <br />
              Delhi, 110031
            </Text>
          </HStack>

          {/* Phone */}
          <HStack alignItems="center" spacing="8px">
            <Box>
              <MdPhoneIphone size="20px" />
            </Box>
            <Text fontSize="large" textAlign="left" lineHeight="1.4">
              +91-9821003890
            </Text>
          </HStack>
        </VStack>
      </Stack>
    </Box>
  );
};
