import { Stack, VStack } from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { AddressCard } from "./AddressCard";
import { MapComponent } from "./MapComponent";

export const ContactUs = () => {
  return (
    <VStack>
      <Navbar currentIndex={4} />
      <Stack
        flexDirection={{ base: "column", sm: "column", md: "row" }}
        width="full"
        marginTop="100px"
        p={{
          md: "120px 25px 0px 25px",
          base: "0px 9px 0px 9px",
          sm: "0px 9px 0px 9px",
        }}
        justifyContent={{ base: "space-between", sm: "space-between", md: "space-around" }}
      >
        <AddressCard />
        <MapComponent />
      </Stack>
    </VStack>
  );
};
