import React from "react";
import VisionImage from "../assets/Vision.png";
import { Box, Text, VStack, Img } from "@chakra-ui/react";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";

export const Vision = () => {
  const { isDesktop } = useCalculatedBreakpoints();
  return (
    <Box w="full">
      <VStack>
        <Img id="maa-ki-kripa-vision-image" src={VisionImage} style={{ height: isDesktop ? "200px" : "150px" }} alt="Vision" />
        <Text id="maa-ki-kripa-vision-txt" fontSize="2xl" fontFamily="serif" bgColor="red.500" color="white" p="25px">
          To be the most trusted and respected professional services firm
          recognized by our clients for delivering excellence. We want to
          revolutionize the garment industry by fostering transparency and trust
          in the supply chain, creating a seamless bridge between retailers and
          manufacturers.
        </Text>
      </VStack>
    </Box>
  );
};
