import React from "react";
import { Navbar } from "./Navbar";
import { VStack, Text, Img, Stack, HStack } from "@chakra-ui/react";
import { useCalculatedBreakpoints } from "../hooks/useCalculateedBreakouts";
import officeLocation from "../assets/officeLocation.png";
import { Location } from "./Location";

export const OurBranches = () => {
  const { isDesktop } = useCalculatedBreakpoints();

  const locations = [
    {
      id: 1,
      title: "Kolkata",
      address: "Bara Bazar & Metiabruz",
      city: "Kolkata, West Bengal, 700007",
      phoneNum: "+91-9821003890"
    },
    {
      id: 2,
      title: "Delhi (Gandhi Nagar & Tank Road)",
      address: "9/3, Ground floor, Geeta Colony",
      city: "Delhi, 110031",
      phoneNum: "+91-9821003890"
    },
    {
      id: 3,
      title: "Kanpur",
      address:"58/44, Opp. ICICI Bank, Birhana Road",
      city: "Kanpur, Uttar Pradesh, 208001",
      phoneNum: "+91-9821003891"
    },
  ];

  return (
    <VStack>
      <Navbar currentIndex={3} />
      <VStack
        display="flex"
        width="full"
        marginTop="100px"
        p={isDesktop ? "0px 25px 0px 25px" : "0px 9px 0px 9px"}
      >
        <Text
          w="full"
          align="center"
          fontSize="xxx-large"
          fontWeight="bold"
          color="red.500"
        >
          Our Presence
        </Text>
        <Img src={officeLocation} h={isDesktop ? "60vh" : "45vh"} />
        <Text
          w="full"
          align="left"
          fontSize="xx-large"
          fontWeight="bold"
          color="red.500"
        >
          Locations
        </Text>
        <Stack flexDirection={isDesktop? "row": "column"} w="full" p={"0px 0px 0px 10px"} justifyContent="space-around">
          {locations.map((location, index) => {
            return <Location key={index} location={location} />;
          })}
        </Stack>
        {/* <VStack w="full" align="flex-start">
          <Text fontSize="xx-large" fontWeight="bold" color="red.500">
            Contact Numbers:
          </Text>
          <Text p={"0px 0px 0px 10px"}>+91-9821003890</Text>
          <Text p={"0px 0px 0px 10px"}>+91-9821003891</Text>
        </VStack> */}
      </VStack>
    </VStack>
  );
};
